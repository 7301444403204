import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"pa-3 black--text",attrs:{"max-width":"380","height":"80","color":_vm.dynamicColor},on:{"click":function($event){!_vm.sel},"mouseover":function($event){_vm.hover = true},"mouseout":function($event){_vm.hover = false}}},[(_vm.clef === 0)?_c('v-tour',{attrs:{"name":"myTour2","steps":_vm.steps,"options":_vm.labels,"button-skip-label":"Passer","button-next-label":"Suivant","button-prev-label":"Précédent"}}):_vm._e(),_c(VBtn,{staticStyle:{"right":"-20px"},attrs:{"disabled":_vm.dynamicColor == 'blue' || _vm.dynamicColor == 'orange',"x-small":"","color":"secondary","fab":"","absolute":"","top":"","right":""},on:{"click":function($event){return _vm.deleteCol(_vm.column.col)}}},[_c(VIcon,[_vm._v("mdi-close")])],1),_c(VRow,[_c(VCol,{attrs:{"cols":"8"}},[_c(VChip,{attrs:{"color":"secondary","dark":""}},[_vm._v(_vm._s(_vm.column.col))])],1),_c(VCol,{attrs:{"cols":"4"}},[_c(VSelect,{staticClass:"customSelect",attrs:{"id":"v-step-11","dark":_vm.dynamicColor == 'blue' || _vm.dynamicColor == 'purple' || _vm.dynamicColor == 'orange',"disabled":_vm.dynamicColor == 'blue',"value":_vm.selectItem,"label":"Type","return-object":"","append-icon":"mdi-cog","items":_vm.knowns},on:{"change":_vm.selectChanged}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }