<template>
  <div>
    <v-tour name="myTour" :steps="steps" :options="labels" button-skip-label="Passer" button-next-label="Suivant"
      button-prev-label="Précédent"></v-tour>


    <v-row justify-center align-items-center align="center" class="pt-3" justify="center">
      <v-card :loading="loading" class="pa-2 ma-2">
        <v-toolbar color="primary" dark flat>
          <v-toolbar-title><b v-if="!dashboard && !nopoints">Etape 3</b> Création d'inventaire
            <v-icon>mdi-auto-fix</v-icon></v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon large target="_blank" v-on="on">
                <v-icon>mdi-upload</v-icon>
              </v-btn>
            </template>
            <span class="white--text">Uploadez vos données</span>
          </v-tooltip>
        </v-toolbar>
        <v-stepper v-model="e1" horizontal>
          <v-stepper-header v-if="!dashboard && !nopoints">
            <v-stepper-step :complete="true" step="1">Inscription du compte</v-stepper-step>
            <v-stepper-step :complete="true" step="2">Choix du plan</v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="3">Importation des données</v-stepper-step>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="3">

              <v-row justify-center align-items-center class="pa-2 ma-2">


                <div v-show="selectMap" id="mapping" style="height: 500px; width: 100%;"></div>

                <v-row v-if="selectMap" justify-center align-items-center class="ma-2">
                  <v-col cols="12" class="d-flex justify-center">
                    <v-btn @click="finishLat" color="secondary" x-large>
                      <v-icon>mdi-check</v-icon>
                      Valider le point de départ
                    </v-btn>
                  </v-col>
                </v-row>


                <v-col v-if="!selectMap" cols="12">
                  <v-text-field :success="nameInv.length > 0"
                    :append-icon="nameInv.length > 0 ? 'mdi-check-circle' : ''" id="v-step-0" @blur="createName()"
                    label="Nom de l'inventaire" name="Nom" placeholder="Ex: " :rules="nomRules"
                    prepend-inner-icon="mdi-clipboard-list" class="required" required v-model="nameInv"
                    type="text"></v-text-field>
                </v-col>

                <!-- <v-col v-if="!selectMap" lg="4" cols="12">
                  <v-select ref="select" :disabled="true" :value="selectDomain"
                    :prepend-inner-icon="selectDomain ? 'mdi-check-circle' : 'mdi-map-search'" :items="listeDomaines"
                    return-object item-text="text" item-value="value" label="Personnifiez votre profil" solo>
                    <template v-slot:prepend-item>
                      <v-list-item ripple>
                        <v-list-item-content>
                          <v-list-item-title>
                            Votre secteur d'activité pour la Cartographie
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>

                    <template v-slot:item="data">
                      <v-list-item @click.prevent="updateSelectDomain(data.item); $refs.select.blur()"
                        :key="data.item.value" link v-ripple>
                        <v-list-item-icon>
                          <v-icon>{{ data.item.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>{{ data.item.text }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                    <template v-slot:selection="{ item, index }">
                      <v-chip>
                        <v-icon dark class="pa-2">{{ item.icon }}</v-icon> <span>{{ item.text }}</span>
                      </v-chip>
                    </template>
                  </v-select>
                </v-col> -->
                <v-col v-if="!selectMap && !nopoints" cols="12">
                  <v-switch inset v-model="noDataGeo" color="blue"
                    label="Je n'ai pas des données géolocalisées (longitude et latitude)"></v-switch>
                </v-col>
                <v-col v-if="!selectMap && !nopoints" lg="8" sm="12" class="d-none d-md-block"></v-col>
                <v-row v-if="!selectMap && !nopoints">
                  <v-col lg="8" cols="12">
                    <v-chip @click="modalCal = true" v-if="dateInv" dark rounded
                      color="success"><v-icon>mdi-calendar-check</v-icon> Date <b class="pa-2">{{ dateInvFormatted
                        }}</b></v-chip>

                    <v-dialog v-model="modalCal" max-width="350" transition="fade-transition" hide-overlay>

                      <template v-if="!dateInv || dateInv.length < 2" v-slot:activator="{ on }">
                        <a class="text-right" v-on="on">
                          <v-icon>mdi-calendar</v-icon>
                          Ajouter une date d'inventaire
                        </a>
                      </template>

                      <v-card>
                        <v-card-text>
                          <!-- Insérer ici le composant du calendrier -->
                          <v-date-picker :value="dateInv" @change="saveSelectedDate" no-title scrollable>
                            <template v-slot:day="{ date }">
                              {{ date.getDate() }}
                            </template>
                          </v-date-picker>
                        </v-card-text>
                        <v-card-actions>
                          <v-btn outlined color="primary" @click="modalCal = false; updateDateInv('')">Annuler</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-col>
                </v-row>

                <v-col cols="12" v-if="progressCols && !selectMap">
                  <v-sheet height="300">
                    <p>{{ messageProgress }}</p>
                    <v-progress-linear indeterminate color="yellow darken-2"></v-progress-linear>
                  </v-sheet>
                </v-col>

                <v-col cols="12" v-if="!selectMap && !selectedFile && !colonnes.length && !nopoints">
                  <vue-dropzone :disablePreviews="true" @vdropzone-drag-enter="dragging = true"
                    @vdropzone-drag-leave="dragging = false" @vdropzone-file-added="fileAddedAsync"
                    :maxFiles="Number(10000000000)" :multipleUpload="true" :parallelUpload="5" ref="myVueDropzone"
                    id="dropzone" :options="dropzoneOptions" :use-custom-slot="true">
                    <div class="dropzone-custom-content">
                      <v-container class="d-flex justify-center align-center text-center">
                        <h3 class="dropzone-custom-title d-flex align-center">
                          <img src="@/assets/uploads.gif" class="mr-2" width="100" />
                          <span>Déposez ici votre fichier Excel, CSV, JSON, GeoJSON, KML</span>
                        </h3>
                      </v-container>
                    <div class="subtitle">
                      <v-list dense>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title><v-icon class="pa-2">mdi-auto-fix</v-icon>Vos données sont
                              automatiquement
                              reconnues</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title><v-icon class="pa-2">mdi-file-chart-check-outline</v-icon>Plusieurs
                              fichiers sont
                              accéptés</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title><v-icon class="pa-2">mdi-map-check</v-icon>Requis: Coordonnées GPS
                              (Longitude,
                              Latitude)</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </div>
  </div>
  </vue-dropzone>
  </v-col>

  <v-row v-if="!selectMap" justify-center align-items-center align="center">
    <v-col cols="12" v-if="colonnes.length && imported">
      <v-progress-linear v-if="colonnes.length && !imported" color="red darken-2" rounded indeterminate
        value="100"></v-progress-linear>
      <!-- <v-alert type="info">
                  <h3>Selectionner les données représentatives à représenter sur la carte</h3>
                </v-alert> -->
      <v-container>
        <v-alert v-if="colonnes.length && imported" border="bottom" type="info" colored-border color="success accent-4"
          elevation="3">


          <v-row class="d-flex justify-center align-stretch">
            <v-col>
              Sélectionnez les données à exploiter sur la carte
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="6" md="4" v-if="isGeo">
              <v-chip color="info" class="pa-3" dark><v-icon>mdi-map-marker-radius</v-icon> Données
                localisées</v-chip>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="isUser">
              <v-chip color="orange" class="pa-3" dark><v-icon>mdi-account-check</v-icon> Données
                utilisateur</v-chip>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="isTemp">
              <v-chip color="purple" class="pa-3" dark><v-icon>mdi-timer</v-icon> Données
                temporelles</v-chip>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-alert v-if="isDiam" class="v-slide-y-transition" icon="mdi-lightbulb-on" border="left" colored-border
                type="info" elevation="2">
                <b>Astuce:</b> Vous pouvez exploiter des colonnes comme <i>la hauteur, la longeur, le
                  diamètre, le volume,
                  la surface...</i>
                afin de créer des filtres pour votre carte personnaliser 🗺️
              </v-alert>
            </v-col>
          </v-row>

          <v-row justify="end">
            <v-col cols="auto">
              <v-switch inset v-model="enteteCol" color="green"
                label="Utiliser la premiere ligne comme l'entête des colonnes"></v-switch>
            </v-col>
          </v-row>

          <v-row dense>
            <v-alert type="info">
              Les colonnes clefs: <i>{{ selectDomain == 'forestier' ? colsRefs.join(', ') :
                colsRefsVigne.join(', ') }}</i>
            </v-alert>

          </v-row>
          <div class="text-right">
            <v-btn color="red" outlined dark @click="resetCols" small>
              <v-icon>mdi-database-refresh-outline</v-icon>Réimporter des
              données</v-btn>
          </div>
        </v-alert>
        <v-row class="mt-2 mr-2" id="colonnes">
          <v-col cols="12" md="4" sm="6" xs="12" v-for="column, key in colonnes" :key="key">
            <Colonne @updateCol="updateCol" :labels="labels" :clef="column.col" :column="column" />
          </v-col>
        </v-row>
      </v-container>
    </v-col>
  </v-row>

  <v-row v-if="!selectMap" class="pa-4" justify-center align-items-center align="center">
    <v-col cols="12" class="text-center">
      <v-btn :disabled="nameInv.length < 1" :class="isVerify ? 'pulse' : ''" @click="uploading" x-large
        color="secondary">
        <v-icon>mdi-file-upload</v-icon>
        Je crée mon inventaire</v-btn>
    </v-col>
    <v-col cols="12" class="text-center mt-4">
      <!-- <v-btn @click="offerModal = true" disable=" true" color="grey" dark>
                      <v-icon>mdi-file-multiple</v-icon>
                      J'ai plusieurs inventaires</v-btn> -->
    </v-col>
  </v-row>


  </v-row>
  </v-stepper-content>

  </v-stepper-items>
  </v-stepper>
  </v-card>

  <!-- Modal pour l'offre payante -->
  <v-dialog v-model="offerModal" max-width="500">
    <v-card>
      <v-card-title>Offre Payante Cartovia</v-card-title>
      <v-card-text>
        <!-- Contenu de votre description de l'offre payante -->
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus vel enim ut libero lacinia tristique nec
        sed
        sem.
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" @click="offerModal = false">Fermer</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  </v-row>
  <v-snackbar :timeout="timeout" shaped absolute :bottom="!poSnack" :top="poSnack" tile :color="colorSnack"
    v-model="snackbar">
    <v-icon dark class=" pa-1">{{ snackIcon }}</v-icon>
    <span class="white--text"> {{ snack }}</span>
  </v-snackbar>
  </div>
</template>


<script>
import xhr from "../plugins/axios";
import axios from "axios"
import Upload from './Upload';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import Colonne from './Colonne.vue'
import { mapGetters, mapActions } from 'vuex';
// import JSConfetti from 'js-confetti';
import mapboxgl from 'mapbox-gl';

export default {
  name: "Import",
  data() {
    return {
      accessToken: 'pk.eyJ1IjoibWFudWVsdGFpd2EiLCJhIjoiY2xsbm9xaHpmMDM2YTNxbzRvOTNhdDhzeCJ9.tcqO1b3kpIZMIhxRCyYR2g', // your access token. Needed if you using Mapbox maps
      mapCenter: [2.2137, 46.2276], // Coordinates for France
      mapping: null,
      marker: null,
      dragging: false,
      fileQueue: [],
      dashboard: false,
      isProcessing: false,
      markerPosition: [2.2137, 46.2276], // Initial marker position at the center of France
      draggable: true,
      noDataGeo: false,
      enteteCol: true,
      displayDesc: false,
      selectedDate: null,
      offerModal: false,
      modalCal: false,
      selectId: null,
      e1: 3,
      messageProgress: '',
      isVerify: false,
      // jsConfetti: new JSConfetti(),
      hover: false,
      type: 'forestier',
      poSnack: 'top',
      snackIcon: 'mdi-alert',
      timeout: 4000,
      colorSnack: 'secondary',
      listeDomaines: [
        { text: 'Gestion Forêstière', value: "forestier", icon: 'mdi-tree' },
        { text: 'Gestion Viticole', value: "vigneron", icon: 'mdi-glass-wine' },
        { text: 'Gestion Agricole', value: "agriculteur", icon: 'mdi-tractor' },
        { text: 'Gestion Minière', value: "miniere", icon: 'mdi-pickaxe' },
        { text: 'Gestion des Risques', value: "risque", icon: 'mdi-alert' },
        { text: "Gestion de l'Eau", value: "eau", icon: 'mdi-water' },
        { text: "Gestion de l'Urbanisme", value: "urbain", icon: 'mdi-city' },
        { text: 'Gestion du Transport', value: "transport", icon: 'mdi-truck' },
        { text: "Gestion de l'aménagement", value: "amenagement", icon: 'mdi-home-city' },
        { text: 'Gestion animale', value: "animale", icon: 'mdi-paw' },
        { text: 'Gestion humaine', value: "humaine", icon: 'mdi-human' },
      ],
      progressCols: false,
      nomRules: [
        (v) => !!v || "Le nom de l'inventaire obligatoire",
        (v) =>
          (v && v.length >= 2) ||
          "Nom doit faire au moins 2 caractères",
        v => (v && v.length <= 50) || "Le nom de l'inventaire  ne doit pas dépasser 50 caractères",
      ],
      steps: [
        {
          target: '#v-step-0',  // We're using document.querySelector() under the hood
          header: {
            title: 'Titre',
          },
          params: {
            placement: 'bottom'
          },
          content: 'Mettez un titre explicite de votre inventaire',
        },
        {
          target: '#type_carte',  // We're using document.querySelector() under the hood
          header: {
            title: 'Type de carte',
          },
          params: {
            placement: 'bottom'
          },
          content: 'Personnaliser votre carte en renseignant votre profil',
        },
        {
          target: '.dropzone',
          header: {
            title: 'Source de données',
          },
          content: "CSV ou Excel, pensez à mettre en majuscules et en 1ère ligne l'entête des colonnes",
          params: {
            placement: 'bottom'
          }
        }
      ],
      displayStats: false,
      nopoints: false,
      selectMap: false,
      nameInv: "",
      preductions: [],
      jsondata: [],
      dataframe: [],
      selectedInventaireObj: {},
      user: {},
      selectedFile: null,
      snack: '',
      snackbar: false,
      valid: false,
      labels: {
        labels: {
          buttonSkip: 'Passer',
          buttonNext: 'Suivant',
          buttonPrevious: 'Précédent',
          buttonStop: 'Finir'
        },
      },
      loading: false,
      finalGps: [],
      colsRefs: [
        "Id",
        "Fôret",
        "Diametre",
        "Volume",
        "Hauteur",
        "Largeur",
        "Longueur",
        "Pente",
        "Essence",
        "Parcelle",
        "Qualite",
        "Etat",
        "Date",
        "Serie",
        "Prix",
        "Produit",
        "Surface",
        "Stock",
        "Quantite",
        "Localisation",
        "Modele",
        "Duree",
        "Nombre",
        "Description",
        "Lien",
        "Photo",
        "Image",
        "URL",
        "Utilisateur",
        "Ville",
      ],
      colsRefsVigne: [
        "Id",
        "Cépage",
        "Domaine",
        "Parcelle",
        "Etat",
        "Hauteur",
        "Largeur",
        "Longueur",
        "Pente",
        "Prix",
        "Date",
        "Produit",
        "Surface",
        "Stock",
        "Quantite",
        "Localisation",
        "Duree",
        "Nombre",
        "Description",
        "Lien",
        "Photo",
        "Image",
        "URL",
        "Utilisateur",
        "Ville",
      ],
      dropzoneOptions: {
        url: 'http://localhost:5000/predict', //'https://cartovia.fr/api/upload',
        disablePreviews: true,
        parallelUploads: 1,
        uploadMultiple: true,
        autoProcessQueue: false,
        maxFilesize: 6,
        acceptedFiles: '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
        dictDefaultMessage: "<i class='mdi mdi-upload'></i>Télécharger votre inventaire au format CSV"
      },
    };
  },
  components: {
    Colonne, Colonne,
    Upload: Upload,
    vueDropzone: vue2Dropzone,
  },
  async created() {

    let nopointsValue = this.$route.query.nopoints;
    if (nopointsValue == "true") {
      this.nopoints = true
    }

    let dashboard = this.$route.query.dashboard;
    if (dashboard == "true") {
      this.dashboard = true
    }


    let user = localStorage.getItem("user");
    if (user) {
      this.user = JSON.parse(user)
      if (this.user.type == "forestier") {
        this.updateSelectDomain({ value: "forestier" })
      } else {
        this.updateSelectDomain("vigneron")
      }
    }

    // console.log("this.selectDomain", this.selectDomain);


    if (this.colonnes.length && !this.imported) {

      // console.log("this.colonnes", this.colonnes);
      this.imported = true

      const existeOne = this.colonnes.some(obj => obj.name && obj.name.toLowerCase().substring(0, 3) === 'lat') && this.colonnes.some(obj => obj.name && obj.name.toLowerCase() === 'longitude' || obj.name === 'lng') || this.colonnes.some(obj => obj.name.toLowerCase() === 'geometry');

      if (existeOne) {
        this.isVerify = true
      }
    }


  },
  async mounted() {


    mapboxgl.accessToken = this.accessToken;

    this.mapping = new mapboxgl.Map({
      container: 'mapping',
      style: 'mapbox://styles/mapbox/streets-v11',
      center: this.mapCenter,
      zoom: 5,
    });

    this.mapping.on('load', () => {

      let nav = new mapboxgl.NavigationControl();
      this.mapping.addControl(nav, 'top-right');

      // Create and add the geolocation button
      const geolocateControl = new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true
        },
        trackUserLocation: true,
        showUserLocation: true,
        showUserHeading: true
      });

      this.mapping.addControl(geolocateControl, 'top-right');

      // Handling the geolocate event to move the marker
      geolocateControl.on('geolocate', (e) => {
        const { longitude, latitude } = e.coords;
        this.finalGps = { lng: longitude, lat: latitude }
        this.marker.setLngLat([longitude, latitude]).addTo(this.mapping);

        // Center the map on the new marker location
        this.mapping.flyTo({
          center: [longitude, latitude],
          zoom: 15
        });
      });

    });

    window.scrollTo({
      top: 0, // Position verticale en haut
      left: 0, // Position horizontale à gauche
      behavior: 'smooth' // Animation de défilement doux
    });

    let user = localStorage.getItem("user");
    setTimeout(() => {
      if (!this.colonnes.length && !this.nameInv.length) {
        this.$tours['myTour'].start()
      }
    }, 2000)

    if (user) {
      this.user = JSON.parse(user)
      console.log("importer le  csv de", this.user);
      if (this.user.displayName) {
        this.user.displayName = this.user.displayName
      } else {
        this.user.displayName = this.user.name
      }
      // let inventaires = await xhr.post('/inventaires', { user: this.user.uid });

      this.snack = `Félicitation 🙏 Votre compte a été crée ! 
      Veillez valider votre compte par e-mail ✉️`
      this.colorSnack = 'blue'
      this.snackIcon = 'mdi-check-decagram'
      if (this.nopoints && !this.dashboard)
        this.snackbar = true

    } else {
      this.snack = "Vous devez vous connectez avant de faire votre premier inventaire"
      this.snackbar = true
      this.colorSnack = 'danger'

      setTimeout(() => {
        localStorage.clear()
        this.$router.push('/login')
      }, 2000);
    }

    let dashboardValue = this.$route.query.dashboard;

    if (dashboardValue == 'true') {
      window.scrollTo({
        top: 0, // Position verticale en haut
        left: 0, // Position horizontale à gauche
        behavior: 'smooth' // Animation de défilement doux
      });
    }

    let firstImport = this.$route.query.first;

    if (firstImport == 'true') {
      this.snack = "Glisser-déposer un fichier Excel, CSV, GeoJSON, JSON  etc."
      this.colorSnack = 'green'
      this.snackIcon = 'mdi-alert-box'
      this.snackbar = true
      this.poSnack = true
      this.timeout = -1
    }

  },
  methods: {
    ...mapActions({
      updateIdCol: 'updateIdCol',
      updateNameInv: 'updateNameInv',
      updateDateInv: 'updateDateInv',
      updateSelectDomain: 'updateSelectDomain',
      updateColonne: 'updateColonne'
    }),
    async finishLat() {

      console.log("this.invId", this.invId);
      let res = await xhr.post('/update-coords', {
        selectId: this.invId,
        noDataGeo: this.noDataGeo,
        finalGps: this.finalGps,
      })

      window.location = '/inventaire/' + res.data._id + '?first=true'
      this.snack = "Merci vos point on été mise à jour depuis votre point"
      this.snackbar = true
    },
    onDragEnd() {
      let lngLat = this.marker.getLngLat();
      this.finalGps = { lng: lngLat.lng, lat: lngLat.lat }
    },
    updateCol(colonne, updates) {
      this.updateColonne({ updates, colonne })
    },
    appearDesc() {
      if (this.nameInv.length > 4) {
        this.displayDesc = true
      } else {
        this.displayDesc = false
      }
    },
    saveSelectedDate(selectedDate) {
      this.modalCal = false;
      this.updateDateInv(selectedDate);
    },
    async resetCols() {
      await this.$store.dispatch('resetColonnes');
      this.selectedFile = null
      this.imported = false
      if (this.$refs.myVueDropzone) {
        this.$refs.myVueDropzone.removeAllFiles();
      }

    },
    updateNameInvInput(value) {
      this.updateNameInv(value);
    },
    createName() {
    },
    update(col) {
    },
    updateCel(val) {
      console.log("Colmun selected", val);
    },
    
    async fileAddedAsync(file) {
      this.fileQueue.push(file);
      if (!this.isProcessing) {
        this.processQueue();
      }
    },
    async processQueue() {
      this.isProcessing = true;
      while (this.fileQueue.length > 0) {
        const file = this.fileQueue.shift();
        await this.uploadAndProcessFile(file);
      }
      this.isProcessing = false;
    },
    async uploadAndProcessFile(file) {
      this.selectedFile = file;
      this.dragging = false;

      console.log("Feuu !!", this.selectedFile);
      let formatData = new FormData();
      let now = new Date();
      let dayOfMonth = now.getDate();
      let hours = now.getHours().toString().padStart(2, '0');
      let minutes = now.getMinutes().toString().padStart(2, '0');
      formatData.append('file', file);
      formatData.append('same', dayOfMonth + hours + minutes); // same for store
      formatData.append('name', this.nameInv);
      formatData.append('profil', this.user.type);
      console.log("this.selectDomain", this.user.type);
      formatData.append('uid', this.user.uid);
      formatData.append('enteteCol', this.enteteCol);
      formatData.append('dateInv', this.dateInv);

      this.messageProgress = '⏳Chargement de votre fichier';
      this.progressCols = true;

      setTimeout(() => {
        this.messageProgress = '📁 Stockage en base de données...';
      }, 4000);

      setTimeout(() => {
        this.messageProgress = '⚡Reconaissance des données...';
      }, 8000);

      setTimeout(() => {
        this.messageProgress = "C'est bientôt finis 🥁";
      }, 10000);

      try {
        let res = await axios.post(`${process.env.VUE_APP_BASE_ML}predict`, formatData);
        if (res.data && res.data.predictions) {
          if (res.data.id){
            this.updateIdCol(res.data.id);

          }
          this.displayStats = true;
          this.imported = true;

          const geoNames = ["longitude", "latitude", "commune", 'lng', 'lat'];

          this.progressCols = false;
          let colonnes = Object.values(res.data.predictions).map((e) => {
            if (typeof e === 'object') {
              return { ...e, name: e.col.charAt(0).toUpperCase() + e.col.slice(1).toLowerCase() };
            } else if (typeof e == 'string') {
              return {
                name: e.charAt(0).toUpperCase() + e.slice(1).toLowerCase()
              };
            } else if (e == false) {
              return {
                name: e
              };
            }
          }).filter((e) => e);

          colonnes.sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });

          let datas = res.data.dataframe;
          this.$store.dispatch('updateColonnes', colonnes);
          this.$store.dispatch('updateDatas', datas);
          this.snack = "Veuillez séléctionner les données à importer";
          this.snackbar = true;

          const existeOne = colonnes.some(obj => obj.name && obj.name.toLowerCase().substring(0, 3) === 'lat') &&
            (colonnes.some(obj => obj.name && obj.name.toLowerCase() === 'longitude' || obj.name === 'lng') ||
              colonnes.some(obj => obj.name.toLowerCase() === 'geometry'));

          if (existeOne) {
            this.isVerify = true;
          }

          // Mettre à jour la collection MongoDB ici
          await this.updateMongoDB(res.data);
        }
      } catch (error) {
        this.snack = `WOOOW 😲 Une erreur est apparue lord de l'import de vos données. Vous pouvez vérifier le format et vos données puis tenter de réimporter.`;
        this.colorSnack = 'red';
        this.snackIcon = 'mdi-alert-box';
        this.snackbar = true;
        this.poSnack = false;
        this.timeout = 3000;

        console.log("error", error);
        this.progressCols = false;
        this.loading = false;
        this.resetCols();
      }
    },
    async updateMongoDB(data) {
      try {
        console.log('Fin de Mongo.. message de success');
        this.snack = `Vos données on été importées!`;
        this.colorSnack = 'green';
        this.snackIcon = 'mdi-check-all';
      } catch (error) {
        console.error('Error updating MongoDB:', error);
      }
    },



    uploadFile(file) {
      console.log("file", file);
    },
    resetAll() {
      let todayDate = new Date().toISOString().slice(0, 10);
      this.loading = false

      this.updateIdCol(null)
      this.updateNameInv("")
      this.updateDateInv(todayDate)
      this.resetCols()
      this.snackbar = true
      this.poSnack = false
      this.timeout = -1
    },
    async uploading() {
      try {
        console.log("this.nopoints", this.nopoints);
        if (this.nopoints == false) {

          // console.log("new colonnes...", this.colonnes);
          this.loading = true
          let formData = {};
          formData.colonnes = this.colonnes
          formData.uid = this.user.uid
          formData.invId = this.invId
          formData.username = this.user.displayName
          formData.email = this.user.email //this.user.email

          let res = await xhr.post('/store-first-inventory', formData)
          if (res.data) {

            this.snack = `WOOOW 😲 Votre import de donnés a été établis avec succès 🥁 
        Vous allez recevoir par e-mail une confirmation  ✉️`
            this.colorSnack = 'success'
            this.snackIcon = 'mdi-send-check'
            this.resetAll()

            // this.jsConfetti.addConfetti();

            // setTimeout(() => {
            // this.$router.push('/login')

            if (!this.noDataGeo) {
              window.location = '/inventaire/' + res.data._id + '?first=true'
              // if (dashboardValue == 'true') {
              //   window.location = '/inventaire/' + res.data._id
              // } else {
              //   window.location = '/inventaire/' + res.data._id
              // }
            } else {

              this.snack = `Veillez séléctionner votre point de départ sur la carte`
              this.colorSnack = 'green'
              this.snackIcon = 'mdi-alert-box'
              this.snackbar = true
              this.selectMap = true
              this.$nextTick(() => {
                this.mapping.resize();
              });

              this.poSnack = false
              this.timeout = 10000
              this.mapping.resize()

              setTimeout(() => {
                this.mapping.flyTo({
                  center: this.mapCenter,
                  zoom: 6,
                  duration: 5000,
                  essential: true // This ensures the animation occurs even if the user prefers reduced motion
                });
              }, 2000)


              this.marker = new mapboxgl.Marker({
                draggable: true,
              }).setLngLat(this.mapCenter).addTo(this.mapping);

              this.marker.on('dragend', this.onDragEnd);

              let description = `<p>⛳ Déplacez-moi pour positionner votre inventaire</p>`
              // Adding a popup to the marker
              let popup = new mapboxgl.Popup({ offset: 25 })
                .setHTML(description);  // Set the text for the popup
              this.marker.setPopup(popup);

              // Open the popup immediately
              this.marker.getPopup().addTo(this.mapping);
            }


            // }, 3000)
          }

        } else {
          console.log("Goo no point...");

          this.snack = `Votre inventaire a été crée`
          this.colorSnack = 'success'
          this.snackIcon = 'mdi-send-check'

          this.resetAll()

          let formData = {}
          formData.name = this.nameInv
          formData.uid = this.user.uid

          let res = await xhr.post('/create-inventory', formData)
          if (res.data) {
            window.location = '/inventaire/' + res.data._id + '?nopoints=true'
          }

        }
      } catch (error) {

        this.snack = `WOOOW 😲 Une erreur est apparue lord de l'import de vos données. Vous pouvez vérifier le format et vos données puis tenter de réimporter.`
        this.colorSnack = 'red'
        this.snackIcon = 'mdi-alert-box'
        this.snackbar = true
        this.poSnack = false
        this.timeout = 3000

        console.log("error", error);
        this.loading = false
        // this.resetCols()

      }





    }
  },
  computed: {
    ...mapGetters({
      invId: 'invId',
      dateInv: 'dateInv',
      datas: 'datas',
      selectDomain: 'selectDomain',
      colonnes: 'colonnes' // 'getColonnes' est le nom du getter défini dans votre store
    }),
    dateInvFormatted() {
      let okDate = new Date(this.dateInv);

      if (okDate) {
        const options = {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        };
        return okDate.toLocaleDateString('fr-FR', options);
      }
      return '';
    },
    isDiam() {
      return this.colonnes.some(col => col.name === "Diametre"
        || col.name === "Longueur" || col.name === "Largeur" || col.name === "Stock" || col.name === "Price"
        || col.name === "Hauteur" || col.name === "Volume" || col.name === "Surface");
    },
    isGeo() {
      const geoNames = ["longitude", "latitude", "commune", 'lng', 'lat'];
      return this.colonnes.some(col => geoNames.includes(col.name.toLowerCase()));
    },
    isUser() {
      const userNames = ["Utilisateur", "Operateur", 'Opérateur'];
      return this.colonnes.some(col => userNames.includes(col.name) || userNames.includes(col.prediction));
    },
    isTemp() {
      const tempNames = ["Date", "Heure"];
      return this.colonnes.some(col => tempNames.includes(col.name));
    }
  },
  watch: {
    colonnes(val) {
      // console.log("laaaa modify", val);
      if (val.length == 0) {
        this.imported = false
        if (this.$refs.myVueDropzone) {
          this.$refs.myVueDropzone.removeAllFiles();
        }
      }
    }
  }
};
</script>

<style></style>
<style lang="scss" scoped>
#mapping {
  width: 100% !important;
  opacity: 0;
  animation: fadeIn 1s ease-out 0.2s;
  animation-fill-mode: forwards;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

#map>* {
  width: 100%;
  height: 100%;
}

.v-select__slot .theme--light.v-chip:not(.v-chip--active) {
  background-color: white !important;
  color: #2e8b57;

  .v-icon.v-icon {
    color: #2e8b57;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.7);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
  }
}

.pulse {
  position: relative;
  animation: pulse 2s infinite;
}

.card-transition {
  transition: background-color 0.3s ease-in-out;
}

.primary-background {
  background-color: var(--v-primary-base) !important;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active in <2.1.8 */
  {
  opacity: 0;
}

#colonnes {

  .primary--text {
    // color: white !important;
  }

  .v-sheet.v-card:not(.v-sheet--outlined) {

    .v-input--selection-controls .v-input__slot>.v-label {
      // color: white !important;
    }
  }
}
</style>