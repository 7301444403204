<template>
    <v-card @click="!sel" @mouseover="hover = true" @mouseout="hover = false" max-width="380" height="80"
        :color="dynamicColor" class="pa-3 black--text">

        <v-tour name="myTour2" v-if="clef === 0" :steps="steps" :options="labels" button-skip-label="Passer"
            button-next-label="Suivant" button-prev-label="Précédent"></v-tour>

        <v-btn :disabled="dynamicColor == 'blue' || dynamicColor == 'orange'" x-small style="right: -20px;"
            color="secondary" fab absolute top right @click="deleteCol(column.col)">
            <v-icon>mdi-close</v-icon>
        </v-btn>

        <v-row>

            <v-col cols="8">
                <v-chip color="secondary" dark>{{ column.col }}</v-chip>
            </v-col>


            <v-col cols="4">
                <v-select class="customSelect" id="v-step-11"
                    :dark="dynamicColor == 'blue' || dynamicColor == 'purple' || dynamicColor == 'orange'"
                    :disabled="dynamicColor == 'blue'" :value="selectItem" @change="selectChanged" label="Type"
                    return-object append-icon="mdi-cog" :items="knowns"></v-select>
            </v-col>


        </v-row>
    </v-card>
</template>

<script>

export default {
    name: "Colonne",
    props: ['column', 'classes', 'clef', 'labels'],
    data() {
        return {
            hover: false,
            sel: false,
            selectItem: null,
            steps: [
                {
                    target: '#v-step-10',  // We're using document.querySelector() under the hood
                    header: {
                        title: 'Colonne à utiliser',
                    },
                    params: {
                        placement: 'top'
                    },
                    content: 'Séléctionner les colonnes à exploiter',
                },
                {
                    target: '#v-step-11',  // We're using document.querySelector() under the hood
                    header: {
                        title: 'Type de données',
                    },
                    params: {
                        placement: 'bottom'
                    },
                    content: 'Séléctionner le type de colonnes parmis notre liste',
                },

            ],
            know: false,
            knowns: [
                null,
                "Id",
                "Code",
                "Série",
                "Lieux",
                "Latitude",
                "Longitude",
                "Localisation",
                "Geometry",
                "Commune",
                "Region",
                "Etat",
                "Cepage",
                "Parcelle",
                "Domaine",
                "Essence",
                "Date-Heure",
                "Date",
                "Heure",
                "Nombre",
                "Coordinates",
                "Diametre",
                "Hauteur",
                "Largeur",
                "Longueur",
                "Volume",
                "Surface",
                "Stock",
                "Score",
                "Price",
                "Cépage",
                "Url",
                "Photo",
                "Type",
                "Prix",
                "Produit",
                "Modèle",
                "Liens",
                "Personne",
                "Opérateur",
                "Utilisateur",
                "Remarque",
                "Commentaire",
                "Chaine de caractères",
                "Booléen",
                "Autre"
            ]
        }
    },
    mounted() {
        if (this.clef === 0) {
            this.$tours['myTour2'].start()
        }
    },
    computed: {

        isMetter() {
            return this.column.name == 'Volume' || this.column.name == 'Diametre' || this.column.name == 'Surface' || this.column.name == 'Longueur' || this.column.name == 'Largeur'
                || this.column.name == 'Hauteur' || this.column.name == 'Surface'
        },
        dynamicColor() {
            const columnNames = ['longitude', 'latitude', 'geometry', 'lat', 'long', 'lng'];
            if (this.column && this.column.name) {
                if (this.column.name == 'Date' || this.column.prediction == 'Date' || this.column.name == 'Heure') {
                    return 'purple'
                }
                if (columnNames.includes(this.column.name.toLowerCase())) {
                    return 'blue'
                }
                else if (this.column.name == 'Operateur' || this.column.name == 'Opérateur' || this.column.name == 'Utilisateur' || this.column.prediction == 'Utilisateur') {
                    return 'orange'
                }
            }

            return this.know ? 'white' : ''
        }
    },
    created() {
        this.updateCol()
    },
    updated() {
        console.log("gooo calcul");
        this.updateCol()
        // if (this.column.name.toLowerCase() == 'long' || this.column.name.toLowerCase() == 'lng') {
        //     this.selectItem = 'Longitude'
        // }

        // if (this.column.name.toLowerCase() == 'lat') {
        //     this.selectItem = 'Latitude'
        // }

        // let col = this.column.prediction
        // if (this.knowns.includes(col)) {
        //     console.log("this.column", this.column, this.column.name);
        //     this.know = true
        //     this.sel = true
        //     // this.selectItem = this.column.prediction
        // }
        // const columnNames = ['longitude', 'latitude', 'geometry', 'lat', 'long', 'lng'];

        // if (columnNames.includes(this.column.name.toLowerCase())) {
        //     this.sel = true
        // }

        // if (this.clef === 0) {
        //     this.$tours['myTour2'].start()
        // }
    },
    methods: {
        selectChanged(val) {
            // console.log(val, this.column, "changed");
            this.$emit('updateCol', this.column, {
                know: this.know,
                selectItem: val
            });

        },
        deleteCol(colonneName) {
            this.$store.dispatch('removeColonne', colonneName);
        },
        updateCol() {
            if (this.column.name.toLowerCase() == 'long' || this.column.name.toLowerCase() == 'lng') {
                this.selectItem = 'Longitude'
            }

            if (this.column.name.toLowerCase() == 'lat') {
                this.selectItem = 'Latitude'
            }

            let col = this.column.prediction
            if (this.knowns.includes(col)) {
                this.know = true
                this.sel = true
                this.selectItem = col
            }
            const columnNames = ['longitude', 'latitude', 'geometry', 'lat', 'long', 'lng'];

            if (columnNames.includes(this.column.name.toLowerCase())) {
                this.sel = true
            }

            if (this.clef === 0) {
                this.$tours['myTour2'].start()
            }
        },
    },
    watch: {
        selectItem(val) {
            console.log("val", val);
            // this.$emit('updateCol', this.column, {
            //     know: this.know,
            //     selectItem: val
            // });
            // if (val == false) {
            //     this.selectItem = null
            //     this.sel = false
            //     this.know = false
            // }
        },
        sel(val) {
            // if (val === true) {
            //     this.know = true
            // } else {
            //     this.know = false
            // }
        },
        know(val) {
            // this.$emit('updateCol', this.column, {
            //     know: val,
            //     selectItem: this.selectItem
            // });
            // if (val === false) {
            //     this.selectItem = null
            // } else {

            // }
        }
    }

}
</script>
<style>
.customSelect,
.v-input--selection-controls .v-input__slot>.v-label,
.v-input--selection-controls .v-radio>.v-label {
    font-size: 0.8rem;
}

.custom-switch-label {
    max-width: 550px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-left: 5px;
}
</style>